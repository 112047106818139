import React from 'react'
import "./Nav.css"
function Home() {
  return (
    <>
    
     <footer> 
      <div class="fixed-bottom" id='box'>
  <div className="text-center" >
  
   <div className="box">
   <div className="tel"><a href="tel:0928658929" ><img src="โทร_Th-(1).gif" width="100%" alt="" /></a></div>
    <div className="line"><a href="https://lin.ee/TsQVKql" target='_blank'><img src="line.gif" width="100%" alt="" /></a></div>
   </div>

  </div>
  </div>
  </footer>
    </>
  )
}

export default Home
