import React from 'react'
import Home from './Home';

function App() {
  return (
    <>
    <div class="container-fluid"> 
     <div className="text-center" >
      <img src="/llogo.jpg" width={150} alt="logo True" className='m-3' />
      <div id='imge'>
        <a href='https://lin.ee/TsQVKql'>   
      <img src="true1.jpg" alt="nettrue" />
      <img src="true2.jpg" alt="nettrue" />
      <img src="true3.jpg" alt="nettrue" /> </a>
    </div>
  </div>
  </div>


    <Home/>      
    </>
  )
}

export default App

